
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #edc886;
  text-decoration: none;
  :hover {
    color: #ffd584;
    text-decoration: none;
  }
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

// Back to top button

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #edc886;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  &:hover {
    background: #151515;
    i {
      color: #edc886;
    }
  }
  &.active {
    visibility: visible;
    opacity: 1;
  }
  i {
    font-size: 28px;
    color: #151515;
    line-height: 0;
  }
}

// Preloader

#preloader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #151515;
  .preloader-content{
    content: "";
    position: fixed;
    top: calc(50% - 0px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
  }
  span {
    position:absolute;
    border: 4px solid #edc886;
    border-top: 4px solid transparent;
    border-radius:999px;
    &:nth-child(1){
      width:80px;
      height:80px;
      animation: spin-1 2s infinite linear;
    }
    &:nth-child(2){
      top: 20px;
      left: 20px;
      width:40px;
      height:40px;
      animation: spin-2 1s infinite linear;
    }
  }
}
@keyframes spin-1 {
  0% {transform: rotate(360deg); opacity: 1;}
  50% {transform: rotate(180deg); opacity: 0.5;}
  100% {transform: rotate(0deg); opacity: 1;}
}
@keyframes spin-2 {
  0% {transform: rotate(0deg); opacity: 0.5;}
  50% {transform: rotate(180deg); opacity: 1;}
  100% {transform: rotate(360deg); opacity: 0.5;}
}

// Disable aos animation delay on mobile devices

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0s !important;
  }
}


// Header

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(0, 0, 0, 0.8);
}

#header .logo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


#header .logo a span {
  color: #edc886;
}

#header .logo img {
  max-height: 80px;
}

.mob{
  #header .logo img {
    max-height: 50px;
  }
}


//  Get Startet Button

.get-started-btn {
  color: #fff;
  border-radius: 4px;
  padding: 7px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #edc886;
}

.get-started-btn:hover {
  background: #ffbb38;
  color: #343a40;
}

@media (max-width: 992px) {
  .get-started-btn {
    padding: 7px 20px 8px 20px;
    margin-right: 15px;
  }
}


// Navigation Menu

/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #edc886;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  background-color: #edc886;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  display: flex;
  align-items: center;
  top: 20px;
  right: 5px;
}

.navbar-mobile ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 30px;
  color: #ffffff;
  font-weight: 500;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #edc886;
  font-weight: 600;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  color: #151515;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  background-color: #edc886;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}


// Hero Section

#home {
  width: 100%;
  height: 100vh;
  background: url("../images/hero-bg.jpg") top center;
  background-size: cover;
  position: relative;
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  .container {
    position: relative;
    padding-top: 74px;
    text-align: center;
  }
  h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    span {
      color: #edc886;
    }
  }
  h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
    &:hover {
      border-color: #edc886;
    }
    h3 {
      font-weight: 700;
      margin: 10px 0 0 0;
      padding: 0;
      font-size: 20px;
      line-height: 26px;
      a {
        color: #fff;
        transition: ease-in-out 0.3s;
      }
    }
    i {
      font-size: 32px;
      line-height: 1;
      color: #edc886;
      :hover {
        color: #edc886;
      }
    }
  }
}

@media (min-width: 1024px) {
  #home {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #home {
    height: auto;
  }

  #home h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #home h2 {
    font-size: 20px;
    line-height: 24px;
  }
}

// Sections General

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
  h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      display: flex;
      background: #ffde9e;
      margin-top: 10px;
    }
  }
  p {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #151515;
  }
}


// About

.about, .about-sl{
  .content {
    h3{
      font-weight: 700;
      font-size: 28px;
      font-family: "Poppins", sans-serif;
    }
  }
}

.about {
  .content {
    p:last-child {
      margin-bottom: 0;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: 0 0 8px 26px;
        position: relative;
      }
      i {
        position: absolute;
        font-size: 20px;
        left: 0;
        top: -3px;
        color: #edc886;
      }
    }
  }
}


// About Sri Lanka

.about-sl {
  padding-top: 60px;
}

.about-sl .icon-box {
  padding-left: 15px;
}

.about-sl .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.about-sl .icon-box i {
  font-size: 48px;
  float: left;
  color: #edc886;
}

.about-sl .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.about-sl .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}


// Services

.services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 40px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  width: 64px;
  height: 64px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  transition: 0.3s;
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 36px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
  color: #edc886;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services {
  .icon-box:hover {
    border-color: #fff;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
  }
}

// Cta

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("../images/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #edc886;
  border-color: #edc886;
  color: #151515;
}

// Contact

.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  background: #ffffff;
  color: #151515;
  float: left;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #151515;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}

//.contact .info .email,
//.contact .info .phone {
//  margin-top: 40px;
//}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #edc886;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #edc886;
  border: 0;
  padding: 10px 24px;
  color: #151515;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #ffcd6b;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Breadcrumbs

.breadcrumbs {
  padding: 15px 0;
  background: whitesmoke;
  min-height: 40px;
  margin-top: 74px;
  h2 {
    font-size: 28px;
    font-weight: 400;
  }
  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li+li {
    padding-left: 10px;
    &::before {
      display: inline-block;
      padding-right: 10px;
      color: #2f2f2f;
      content: "/";
    }
  }
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
    .d-flex {
      display: block !important;
    }
    ol {
      display: block;
      li {
        display: inline-block;
      }
    }
  }
}

// Footer

.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #edc886;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  span {
    color: #757575;
    font-size: 15px;
  }
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo img {
  max-width: 200px;
  object-fit: scale-down;
  object-position: center;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
  text-align: justify;
}

.footer-social-icon {
  span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
  }
  a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
  }
  i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  }
}

.footer-widget-heading {
  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      height: 2px;
      width: 50px;
      background: #edc886;
    }
  }
}

.footer-widget {
  ul {
    li {
      display: inline-block;
      float: left;
      width: 50%;
      margin-bottom: 12px;
      a {
        color: #878787;
        text-transform: capitalize;
        &:hover{
          color: #edc886;
        }
      }
    }
  }
}

.subscribe-form {
  position: relative;
  overflow: hidden;
  input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
  }
  button {
    position: absolute;
    right: 0;
    background: #edc886;
    padding: 13px 20px;
    border: 1px solid #edc886;
    top: 0;
    i {
      color: #fff;
      font-size: 22px;
      transform: rotate(-6deg);
    }
  }
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text {
  p {
    margin: 0;
    font-size: 14px;
    color: #878787;
    a{
      color: #edc886;
    }
  }
}

.footer-menu {
  li {
    display: inline-block;
    margin-left: 20px;
  }
}

.footer-menu{
  li {
    &:hover a{
      color: #edc886;
    }
    a {
      font-size: 14px;
      color: #878787;
    }
  }
}

.accordion-button {
  &:focus{
    box-shadow: none;
  }
  &:not(.collapsed) {
    color: #000;
    background-color: #f3f3f3;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
}

.tours {
  .badge {
    background-color: #edc886;
    color: #000;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 5px;
      font-size: 14px;
      i {
        margin-right: 5px;
        font-size: 16px;
        color: #edc886;
      }
    }
  }
}

.carousel-item{
  img{
    object-position: center;
    object-fit: cover;
    height: 466px;
  }
}
.mob{
  .carousel-item{
    img{
      height: 263px;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
}